import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import MapView from './views/mapView';
import {
  OneData,
  TwoData,
  ThreeData,
  FourData,
  FiveData,
  SixData,
  SevenData,
  EightData,
  NineData,
  TenData,
  ElevenData,
  TwelveData,
  ThirteenData,
  FourteenData,
  FifteenData,
  SixteenData,
  SeventeenData,
  EighteenData,
  NineteenData,
  TwentyData,
  TwentyOneData,
  TwentyTwoData,
  TwentyThreeData,
  TwentyFourData,
  TwentyFiveData,
  TwentySixData,
  TwentySevenData,
  TwentyEightData,
  TwentyNineData,
  ThirtyData
} from './Data'

export default function App() {

  return (
    <Router>
        <Switch>

          <Route exact path="/1">
            <MapView zoomSetting={11} centerPosition={[24.50,54.44]} locationData={OneData} />
          </Route>

          <Route exact path="/2">
            <MapView zoomSetting={11} centerPosition={[24.50,54.54]} locationData={TwoData} />
          </Route>

          <Route exact path="/3">
            <MapView zoomSetting={10.5} centerPosition={[24.50,54.40]} locationData={ThreeData} />
          </Route>

          <Route exact path="/4">
            <MapView zoomSetting={11.5} centerPosition={[24.49,54.4185]} locationData={FourData} />
          </Route>

          <Route exact path="/5">
            <MapView zoomSetting={10} centerPosition={[24.49,54.5185]} locationData={FiveData} />
          </Route>

          <Route exact path="/6">
            <MapView zoomSetting={11.5} centerPosition={[24.49,54.37]} locationData={SixData} />
          </Route>
          
          <Route exact path="/7">
            <MapView zoomSetting={11} centerPosition={[24.49,54.37]} locationData={SevenData} />
          </Route>

          <Route exact path="/8">
            <MapView zoomSetting={11} centerPosition={[24.49,54.405]} locationData={EightData} />
          </Route>

          <Route exact path="/9">
            <MapView zoomSetting={11} centerPosition={[24.49,54.39]} locationData={NineData} />
          </Route>
 
          <Route exact path="/10">
            <MapView zoomSetting={13} centerPosition={[24.505,54.6]} locationData={TenData} />
          </Route>

          <Route exact path="/11">
            <MapView zoomSetting={10.5} centerPosition={[24.48,54.52]} locationData={ElevenData} />
          </Route>

          <Route exact path="/12">
            <MapView zoomSetting={10} centerPosition={[24.49,54.54]} locationData={TwelveData} />
          </Route>

          <Route exact path="/13">
            <MapView zoomSetting={10} centerPosition={[24.40,54.37]} locationData={ThirteenData} />
          </Route>

          <Route exact path="/14">
            <MapView zoomSetting={10} centerPosition={[24.49,54.5]} locationData={FourteenData} />
          </Route>

          <Route exact path="/15">
            <MapView zoomSetting={7} centerPosition={[23.5,54.37]} locationData={FifteenData} />
          </Route>

          <Route exact path="/16">
            <MapView zoomSetting={7.5} centerPosition={[24.49,54.97]} locationData={SixteenData} />
          </Route>

          <Route exact path="/17">
            <MapView zoomSetting={10} centerPosition={[24.49,54.47]} locationData={SeventeenData} />
          </Route>

          <Route exact path="/18">
            <MapView zoomSetting={11.5} centerPosition={[24.46,54.585]} locationData={EighteenData} />
          </Route>

          <Route exact path="/19">
            <MapView zoomSetting={10.5} centerPosition={[24.49,54.47]} locationData={NineteenData} />
          </Route>

          <Route exact path="/20">
            <MapView zoomSetting={7} centerPosition={[25.5,55.09]} locationData={TwentyData} />
          </Route> 

          <Route exact path="/21">
            <MapView zoomSetting={10.5} centerPosition={[24.49,54.42]} locationData={TwentyOneData} />
          </Route>

          <Route exact path="/22">
            <MapView zoomSetting={12} centerPosition={[24.49,54.40]} locationData={TwentyTwoData} />
          </Route> 

          <Route exact path="/23">
            <MapView zoomSetting={7.5} centerPosition={[24.5,54.9]} locationData={TwentyThreeData} />
          </Route>

          <Route exact path="/24">
            <MapView zoomSetting={6.5} centerPosition={[24.9,53.5]} locationData={TwentyFourData} />
          </Route> 


          <Route exact path="/25">
            <MapView zoomSetting={11.5} centerPosition={[24.505,54.4]} locationData={TwentyFiveData} />
          </Route>

          <Route exact path="/26">
            <MapView zoomSetting={12} centerPosition={[24.47,54.34]} locationData={TwentySixData} />
          </Route> 

          <Route exact path="/27">
            <MapView zoomSetting={7} centerPosition={[24.49,54.37]} locationData={TwentySevenData} />
          </Route>

          <Route exact path="/28">
            <MapView zoomSetting={10.5} centerPosition={[24.49,54.37]} locationData={TwentyEightData} />
          </Route> 

          <Route exact path="/29">
            <MapView zoomSetting={9} centerPosition={[24.49,54.47]} locationData={TwentyNineData} />
          </Route> 

          <Route exact path="/30">
            <MapView zoomSetting={10} centerPosition={[24.49,54.47]} locationData={ThirtyData} />
          </Route>

          <Route exact path="*">
            <MapView zoomSetting={10} centerPosition={[24.49,54.47]} locationData={OneData} />
          </Route>

        </Switch>
    </Router>
  );
}