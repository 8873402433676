import '../App.css';
import {useState} from 'react';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import L from "leaflet";
import Legend from '../components/Legend';
import TopLegend from '../components/TopLegend';
import LocationPin from '../fonts/pin.png';

function App(props) {


  const [map, setMap] = useState(null);


  var customIcon = L.icon({
    iconUrl: LocationPin,
    iconAnchor:   [15, 34], // point of the icon which will correspond to marker's location
    popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
});

  // token -- pk.eyJ1IjoicGF0aW5zaHV0aSIsImEiOiJja294YXoyM20wNmw5MnBtc2w0Njl4d2RsIn0.VFnRKQZED2KqQCnoRN2S8w

  // style -- mapbox://styles/patinshuti/ckoy8ttox2as517mhjlb95oug


  // api --- https://api.mapbox.com/styles/v1/patinshuti/ckoy7dsp2295u17p48e09xl74/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicGF0aW5zaHV0aSIsImEiOiJja294YXoyM20wNmw5MnBtc2w0Njl4d2RsIn0.VFnRKQZED2KqQCnoRN2S8w

  const markers = props.locationData;
  const centerPosition = props.centerPosition;
  const zoomSetting = props.zoomSetting;

  return (
    <div className="App">
      <MapContainer whenCreated={setMap} center={centerPosition} zoom={zoomSetting} scrollWheelZoom={false} touchZoom={false} dragging={false} doubleClickZoom={false} zoomControl={false}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://api.mapbox.com/styles/v1/patinshuti/ckoy8ttox2as517mhjlb95oug/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicGF0aW5zaHV0aSIsImEiOiJja294YXoyM20wNmw5MnBtc2w0Njl4d2RsIn0.VFnRKQZED2KqQCnoRN2S8w"
        />

        <TopLegend map={map} position={"topleft"}/>

        {
            markers.map((data,index)=>(
                <Marker key={index} icon={customIcon} eventHandlers={{ click: (e) => {window.location.href=data.link} }} position={data.locationPosition}>
                    <Tooltip direction={data.toolTipDirection} offset={data.offset} opacity={1} permanent>
                        <span className="tooltip-wrap" style={{fontWeight:"800", fontSize:"11.5px"}}>{data.placeName}</span>
                    </Tooltip>
                </Marker>
            ))
        }

        <Legend map={map} position={"bottomleft"}/>

      </MapContainer>
    </div>
  );
}

export default App;
