export const OneData =   [
    { 
        placeName:"MANARAT AL SAADIYAT",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.50,54.44],
        link:"https://www.google.com/maps/dir/24.4449844,54.3576579/escape+rooms/@24.4557596,54.3416925,15z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e65e81d5f6021:0xaa1d41c9f5053406!2m2!1d54.3459311!2d24.4659489?hl=en-us"
    },

    { 
        placeName:"ART HOUSE CAFE",
        offset:[5, 9],
        toolTipDirection: "bottom",
        locationPosition: [24.460691850735834, 54.34867082616787],
        link:"https://www.google.com/maps/place/Art+House+Cafe/@24.4604819,54.3464714,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5e6607a76e44fd:0xc090eb872c6410d5!8m2!3d24.4604819!4d54.3486601"
    },

    { 
        placeName:"L'ATELIER DES ARTS",
        offset:[0, -16],
        toolTipDirection: "right",
        locationPosition:[24.45824731776677, 54.38133248398213],
        link:"https://www.google.com/maps/place/l'Atelier+des+Arts/@24.4573928,54.3791116,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5e666fb4e785b9:0x8dcdfe343a505fd!8m2!3d24.4573928!4d54.3813003"
    },

    { 
        placeName:"ABU DHABI POTTERY ESTABLISHEMNT",
        offset:[15, -20],
        toolTipDirection: "top",
        locationPosition:[24.4746994591115, 54.347766174292886],
        link:"https://www.google.com/maps/dir/24.4449844,54.3576579/escape+rooms/@24.4557596,54.3416925,15z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e65e81d5f6021:0xaa1d41c9f5053406!2m2!1d54.3459311!2d24.4659489?hl=en-us"
    },

    { 
        placeName:"RFLCT",
        offset:[5, -30],
        toolTipDirection: "top",
        locationPosition:[24.4119, 54.5365],
        link:"https://www.google.com/maps/dir/24.5125682,54.3795267/Emirates+zoo/@24.4854625,54.3884299,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e51ddc400a3e3:0xe6a61c20bf520175!2m2!1d54.6736902!2d24.5457068?hl=en-us"
    }
  ]


export const TwoData = [
    {
        placeName:"CARACAL SHOOTING",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.402443933334663, 54.47536840000001],
        link:"https://www.google.com/maps/place/Caracal+Shooting+Club/@24.4016623,54.4753684,15z/data=!4m5!3m4!1s0x0:0x6ba21d7a037ea6ec!8m2!3d24.401654!4d54.4753287?hl=en-us&shorturl=1"
    },

    {
        placeName:"AL FORSAN SPORTS",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.419682338469578, 54.54915436082629],
        link:"https://www.google.com/maps/dir/24.4449844,+54.3576579/Al+Forsan+International+Sports+Resort,+Khalifa+City+,+Street+No.12,+Near+Abu+Dhabi+Golf+Club+-+Abu+Dhabi+-+United+Arab+Emirates/@24.4355165,54.383343,12z/data=!3m1!4b1!4m12!4m11!1m3!2m2!1d54.3576579!2d24.4449844!1m5!1m1!1s0x3e5e4693f9ef31fb:0xa6f7cd3284fa0d5!2m2!1d54.5483077!2d24.4088318!3e0"
    },

    {
        placeName:"TASLEEH",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.495103633741987, 54.609233085896044],
        link:"https://www.google.com/maps/dir/24.4449844,54.3576579/tasleeh/@24.475107,54.5007431,12z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e4f874ed2f775:0xa743faf9fe1abf38!2m2!1d54.6087777!2d24.4885048?hl=en-us"
    }
     
]


export const ThreeData = [
    {
        placeName:"OBSERVATION DECK",
        offset:[5,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.464602710860543, 54.321791799146574],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/Tower+2,+Level+74,+Etihad+Towers+-+Corniche+W+St+-+Abu+Dhabi/@24.4824139,54.2696067,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e6618478643b3:0xc98170eb2833ebc3!2m2!1d54.3216462!2d24.4582208!11m1!4b1"
    },

    {
        placeName:"AZURA PANORAMIC LOUNGE",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.47119645415825, 54.328029903873244],
        link:"https://www.google.com/maps/dir//The+St.Regis+Nation+Tower,+The+St.Regis+60476+-+Abu+Dhabi/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3e5e65ebafc70c89:0xf494393c7553d60d!11m1!4b1?entry=s&sa=X"
    },

    {
        placeName:"FOUQUET",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.539340148302596, 54.397421655867106],
        link:"https://www.google.com/maps/dir//Saadiyat+Cultural+District+1+-+Al+Sa'Diyat+-+Abu+Dhabi/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3e5e676dae7338d5:0x1950d050e81aaeff!11m1!4b1?entry=s&sa=X"

    },

    {
        placeName:"PURA RESTAURANT",
        offset:[5,-18],
        toolTipDirection: "left",
        locationPosition:[24.42238164260921, 54.5],
        link:"https://www.google.ae/maps/place/Abu+Dhabi+Falcon+Hospital/@24.3404531,54.5238858,11z/data=!4m5!3m4!1s0x0:0x60ce585fb407d4fb!8m2!3d24.4073267!4d54.6993359?hl=en-ae&shorturl=1"
    }
     
]

export const FourData = [
    {
        placeName:"XTREME ZONE",
        offset:[5,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.496846716580013, 54.38288259733351],
        link:"https://www.google.com/maps/place/Xtreme+Zone/@24.4966173,54.3806939,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5e679bd591cd33:0x2cd11ac4294a4269!8m2!3d24.4966173!4d54.3828826?hl=en-US"
    },

    {
        placeName:"ABU DHABI COUNTRY CLUB",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.443280612518738, 54.38469246849746],
        link:"https://www.google.com/maps/place/Abu+Dhabi+Country+Club/@24.4430804,54.3825145,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5e662715555555:0x94ff5a29e6bab99!8m2!3d24.4430804!4d54.3847032?hl=en-US"
    },

    {
        placeName:"ZAYED SPORTS CITY",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.416011255824237, 54.454376383836966],
        link:"https://www.google.com/maps/place/Zayed+Sports+City/@24.415811,54.4521555,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5e42158b1fb7ed:0x195702ac4d6417f3!8m2!3d24.415811!4d54.4543442?hl=en-US"

    },
     
]


export const FiveData = [
    {
        placeName:"AL FAY PARK",
        offset:[5,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.492399256513362, 54.405109553980125],
        link:"https://www.google.ae/maps/place/Al+Fay+Park/@24.4915596,54.4052383,15z/data=!4m5!3m4!1s0x0:0x371ed63f7aa1254a!8m2!3d24.4915596!4d54.4052383?hl=en-aeS"
    },

    {
        placeName:"MASDAR CITY",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.42761168484043, 54.615912294848734],
        link:"https://www.google.com/maps/place/Abu+Dhabi+Country+Club/@24.4430804,54.3825145,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5e662715555555:0x94ff5a29e6bab99!8m2!3d24.4430804!4d54.3847032?hl=en-US"
    }
     
]


export const SixData = [
    {
        placeName:"DARK ROOM HORROR",
        offset:[5,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.466599679461417, 54.346069864675705],
        link:"https://www.google.com/maps/dir/24.4449844,54.3576579/escape+rooms/@24.4557596,54.3416925,15z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e65e81d5f6021:0xaa1d41c9f5053406!2m2!1d54.3459311!2d24.4659489?hl=en-us"
    },

    {
        placeName:"CHALLENGE CHAMBERS",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.483305419029623, 54.37370967112923],
        link:"https://www.google.com/maps/place/Challenge+Chambers+Abu+Dhabi/@24.4979898,54.3016119,11z/data=!4m5!3m4!1s0x0:0xf1e04b3e162353c!8m2!3d24.4698373!4d54.373766?hl=en-us"
    }
     
]

export const SevenData = [
    {
        placeName:"AL HUDAYRIAT ISLAND",
        offset:[5,-10],
        toolTipDirection: "top",
        locationPosition:[24.43209906598605, 54.34143550811328],
        link:"https://www.google.com/maps/place/Al+Hudayriat+Island/@24.372226,54.3215228,12z/data=!3m1!4b1!4m5!3m4!1s0x3e5e6f35f2ff0d6f:0x4c12341b13da96f7!8m2!3d24.4251255!4d54.3412497"
    },

    {
        placeName:"MINA MARSA",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.530146219968017, 54.379210630679914],
        link:"https://www.google.ae/maps/place/Marsa+Mina/@24.5293849,54.3791248,15z/data=!4m5!3m4!1s0x0:0x668f8f5c3ac3bd24!8m2!3d24.5293849!4d54.3791248?hl=en-ae"
    }
     
]


export const EightData = [
    {
        placeName:"SHEIKH ZAYED FOUNDER'S",
        offset:[5,-26],
        toolTipDirection: "top",
        locationPosition:[24.46847091501801, 54.32355923695781],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/sheikh+zayed+memorial/@24.4848755,54.2700009,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e6594f0b5307d:0x30ecfad83365e05!2m2!1d54.3224345!2d24.4631441?hl=en-us"
    },

    {
        placeName:"WAHAT ALKARAMA",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.425919988224273, 54.47587000421057],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/karama+center+abu+dhabi/@24.4599882,54.2781161,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e43d2d9161a35:0xb7f1e4570d52f974!2m2!1d54.4787558!2d24.4134662"
    }
     
]


export const NineData = [
    {
        placeName:"EMIRATES PALACE",
        offset:[5,-26],
        toolTipDirection: "top",
        locationPosition:[24.462600644900824, 54.3172833],
        link:"https://www.google.com/maps/place/Emirates+Palace+-+W+Corniche+Rd+-+Al+Ras+Al+Akhdar+-+Abu+Dhabi+-+United+Arab+Emirates/@24.4616436,54.3172833,17z/data=!4m2!3m1!1s0x3e5e6596f8aa2c21:0x634ef65bd7bd41c8"
    },

    {
        placeName:"LOUVRE PARK",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.537188801432812, 54.39991886410983],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/louvre+park/@24.5192292,54.3089383,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e67a165fc1f1d:0xda888f2e31d004ae!2m2!1d54.4003094!2d24.5318516"
    },

    {
        placeName:"MANGROVE NATIONAL PARK",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.45624079208773, 54.41805809203975],
        link:"https://www.google.com/maps/place/Mangrove+National+Park/@24.456065,54.4178006,15z/data=!4m5!3m4!1s0x0:0x9c240c7576f324df!8m2!3d24.456065!4d54.4178006"
    },

    {
        placeName:"SECRET GARDEN",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.411327671471096, 54.45747331446676],
        link:"https://www.google.com/maps/place/Mangrove+National+Park/@24.456065,54.4178006,15z/data=!4m5!3m4!1s0x0:0x9c240c7576f324df!8m2!3d24.456065!4d54.4178006"
    }

]


export const TenData = [
    {
        placeName:"WARNER BROS WORLD",
        offset:[5,-8],
        toolTipDirection: "bottom",
        locationPosition:[24.491845068424922, 54.59945047669979],
        link:"https://www.google.com/maps/place/Warner+Bros+World+Abu+Dhabi/@24.4909273,54.5992359,15z/data=!4m5!3m4!1s0x0:0x5a3f2432f3fb33b9!8m2!3d24.4909273!4d54.5992359?hl=en-us"
    },

    {
        placeName:"YAS WATER WORLD",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.5011248473992, 54.59948698778808],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/yas+waterworld/@24.4970635,54.3385617,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e457acea5a845:0x12d3db84713d00d9!2m2!1d54.599647!2d24.487617"
    },

    {
        placeName:"FERRARI WORLD",
        offset:[-10,-20],
        toolTipDirection: "right",
        locationPosition:[24.496073950642117, 54.60591336938108],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/ferrari+world/@24.4951367,54.3422415,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e457e2d394a05:0x6076df4876c470a9!2m2!1d54.6070066!2d24.4837634"
    }

]

export const ElevenData = [
    {
        placeName:"CAFE DISTRICT 10",
        offset:[5,-26],
        toolTipDirection: "top",
        locationPosition:[24.44197432528333, 54.46246324131776],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/cafe+district+10/@24.4672887,54.2702025,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e424442d6e9ad:0x3c6f3cde4acdb877!2m2!1d54.4629286!2d24.4280673"
    },

    {
        placeName:"DRVN COFFEE",
        offset:[5,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.434862001459656, 54.448422932184044],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/drvn+cafe/@24.4651778,54.262342,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e4391d54f2581:0x379454755074e6b5!2m2!1d54.4472076!2d24.4238456"
    },

    {
        placeName:"FERRARI WORLD",
        offset:[15,-17],
        toolTipDirection: "left",
        locationPosition:[24.496073950642117, 54.60591336938108],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/ferrari+world/@24.4951367,54.3422415,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e457e2d394a05:0x6076df4876c470a9!2m2!1d54.6070066!2d24.4837634"
    },

    {
        placeName:"LOCAL CAFE",
        offset:[23,-15],
        toolTipDirection: "left",
        locationPosition:[24.457379365901836, 54.614303966830484],
        link:"https://www.google.com/maps/place/LOCAL/@24.4560121,54.6046051,14z/data=!4m5!3m4!1s0x0:0x9b0019135c621893!8m2!3d24.455508!4d54.6146338?hl=en-us"
    }

]

export const TwelveData = [
    {
        placeName:"ANIMAL MARKET",
        offset:[5,-26],
        toolTipDirection: "top",
        locationPosition:[24.51073577193279, 54.379290615339954],
        link:"https://www.google.ae/maps/place/Abu+Dhabi+Animal+Market%2F+Mina+Animal+Market/@24.5096229,54.3792477,15z/data=!4m5!3m4!1s0x0:0xd255e53b70a78b10!8m2!3d24.5096924!4d54.3792918?hl=en-ae"
    },

    {
        placeName:"EMIRATES PARK ZOO",
        offset:[5,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.55699234731007, 54.67476106805404],
        link:"https://www.google.com/maps/dir/24.5125682,54.3795267/Emirates+zoo/@24.4854625,54.3884299,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e51ddc400a3e3:0xe6a61c20bf520175!2m2!1d54.6736902!2d24.5457068?hl=en-us"
    },

    {
        placeName:"FALCON HOSPITAL",
        offset:[8,-16],
        toolTipDirection: "left",
        locationPosition:[24.427070600321183, 54.698980387028136],
        link:"https://www.google.ae/maps/place/Abu+Dhabi+Falcon+Hospital/@24.3404531,54.5238858,11z/data=!4m5!3m4!1s0x0:0x60ce585fb407d4fb!8m2!3d24.4073293!4d54.6993654?hl=en-ae"
    }

]


export const ThirteenData = [

    {
        placeName:"SOUL BEACH",
        offset:[5,-26],
        toolTipDirection: "top",
        locationPosition:[24.54302768340554, 54.410168365501974],
        link:"https://www.google.com/maps/place/Soul+Beach/@24.5463849,54.3918006,13z/data=!4m5!3m4!1s0x0:0x970f8cf59b5a0f34!8m2!3d24.5394763!4d54.4102893?hl=en-us"
    },

    {
        placeName:"COVE BEACH",
        offset:[-20,-18],
        toolTipDirection: "right",
        locationPosition:[24.503465405500506, 54.415426962860685],
        link:"https://www.google.com/maps/place/Cove+Beach+Abu+Dhabi+at+Makers+District/@24.5021133,54.4152231,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5e670afe9408d9:0xf47ec19ad542ddd0!8m2!3d24.5032824!4d54.4153934"
    },


    {
        placeName:"CORNICHE BEACH",
        offset:[5,-18],
        toolTipDirection: "left",
        locationPosition:[24.478191547312957, 54.33761131872228],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/Corniche+Beach/@24.4892836,54.2775298,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e65dcfeaf7815:0xc955f11be27309c7!2m2!1d54.3374924!2d24.4719602?hl=en-us"
    },



    {
        placeName:"ALBATEEN BEACH",
        offset:[-5,-16],
        toolTipDirection: "right",
        locationPosition:[24.430292548955297, 54.377660187019195],
        link:"https://www.google.com/maps/dir/AlBateen+Public+Beach,+Khalifa+Al+Mubarak+Street+-+Abu+Dhabi+-+United+Arab+Emirates/24.4271667,54.3771452/@24.4274011,54.3422979,13z/data=!4m8!4m7!1m5!1m1!1s0x3e5e68e505b14b55:0xf2512a878a3585a8!2m2!1d54.3773175!2d24.427327!1m0?hl=en-us"
    },

    {
        placeName:"AL HUDAYRIAT ISLAND",
        offset:[5,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.43209906598605, 54.34143550811328],
        link:"https://www.google.com/maps/place/Al+Hudayriat+Island/@24.372226,54.3215228,12z/data=!3m1!4b1!4m5!3m4!1s0x3e5e6f35f2ff0d6f:0x4c12341b13da96f7!8m2!3d24.4251255!4d54.3412497"
    },

    {
        placeName:"AL MIRFA BEACH",
        offset:[5,-25],
        toolTipDirection: "top",
        locationPosition:[24.24209906598605, 54.31143550811328],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/al+mirfa+beach/@24.3035525,53.3607647,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5da33bf8e03cfb:0x339cfbd6e1aaeb65!2m2!1d53.4848172!2d24.1025224"
    },

]



export const FourteenData = [

    {
        placeName:"CIRCUIT X",
        offset:[5,-26],
        toolTipDirection: "top",
        locationPosition:[24.41796026033758, 54.33328519733207],
        link:"https://www.google.com/maps/place/Circuit+X/@24.4177307,54.3310965,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5e6f675b9224eb:0xd37fa4b7e691aed6!8m2!3d24.4177307!4d54.3332852"
    },

    {
        placeName:"YAS MARINA CIRCUIT",
        offset:[0,-25],
        toolTipDirection: "top",
        locationPosition:[24.485877028872437, 54.60550666962249],
        link:"https://www.google.com/maps/place/Yas+Marina+Circuit+-+Yas+IslandYas+West+-+Abu+Dhabi+-+United+Arab+Emirates/@24.4699413,54.465431,11z/data=!4m5!3m4!1s0x3e5e4586238307c7:0x1c5f17a631904450!8m2!3d24.4699413!4d54.6055067"
    },

    {
        placeName:"MASDAR CITY",
        offset:[5,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.42761168484043, 54.615912294848734],
        link:"https://www.google.com/maps/place/Abu+Dhabi+Country+Club/@24.4430804,54.3825145,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5e662715555555:0x94ff5a29e6bab99!8m2!3d24.4430804!4d54.3847032?hl=en-US"
    },

    {
        placeName:"AL WATHBA CYCLE TRACK",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.15599513460309, 54.582753281987105],
        link:"https://www.google.com/maps/place/Al+Wathba+Cycle+Track+-+Al+Wathba+-+Abu+Dhabi+-+United+Arab+Emirates/@24.1557651,54.7806075,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5e2b559d74db49:0x2f83db57edcd3038!8m2!3d24.1557651!4d54.7827962"
    },
    


]


export const FifteenData = [

    {
        placeName:"SWEIHAN",
        offset:[5,-26],
        toolTipDirection: "top",
        locationPosition:[24.462408739259057, 55.33221340306583],
        link:"https://www.google.com/maps/dir//Sweihan+-+Abu+Dhabi+-+United+Arab+Emirates/@24.458268,55.2973661,13z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x3e5ff163e1b39225:0x6e113ad7c18b5c1c!2m2!1d55.3323857!2d24.4581939!3e2"
    },

    {
        placeName:"FOSSIL DUNES",
        offset:[5,-26],
        toolTipDirection: "top",
        locationPosition:[24.1583560, 54.5395740],
        link:"https://www.google.com/maps/place/Fossil+Dunes+Abu+Dhabi/@23.7559063,54.5999388,6z/data=!4m5!3m4!1s0x0:0x4c694dc5b682f16e!8m2!3d24.1716941!4d54.5780091"
    },


    {
        placeName:"LIWA",
        offset:[5,-26],
        toolTipDirection: "top",
        locationPosition:[22.926992304229028, 53.99324523566159],
        link:"https://www.google.com/maps/dir//Bateen+Liwa+-+Abu+Dhabi+-+United+Arab+Emirates/@22.9240674,53.9585696,13z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x3e66db30b8ef221d:0x5ba50dd8fbd96b3e!2m2!1d53.9935892!2d22.9239922!3e2"
    }

]


export const SixteenData = [

    {
        placeName:"MAZARAA FARM SHOP",
        offset:[5,-13],
        toolTipDirection: "bottom",
        locationPosition:[24.4643162, 54.3576580],
        link:"https://www.google.com/maps/dir//Sweihan+-+Abu+Dhabi+-+United+Arab+Emirates/@24.458268,55.2973661,13z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x3e5ff163e1b39225:0x6e113ad7c18b5c1c!2m2!1d55.3323857!2d24.4581939!3e2"
    },

    {
        placeName:"AL RAHBAH",
        offset:[5,-26],
        toolTipDirection: "top",
        locationPosition:[24.607516694880154, 54.69658369648553],
        link:"https://www.google.com/maps/place/Al+Rahbah+-+Abu+Dhabi+-+United+Arab+Emirates/@24.597957,54.6770143,14z/data=!4m19!1m13!4m12!1m3!2m2!1d54.6958971!2d24.5967473!1m6!1m2!1s0x3e5e519bf23f0341:0xcd5590db2ad175ca!2sAl+Rahbah+-+Abu+Dhabi+-+United+Arab+Emirates!2m2!1d54.6966264!2d24.6056498!3e2!3m4!1s0x3e5e519bf23f0341:0xcd5590db2ad175ca!8m2!3d24.6056498!4d54.6966264"
    },

    {
        placeName:"EMIRATES BIO FARM",
        offset:[-25,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.685099459150496, 55.74174364601987],
        link:"https://www.google.com/maps/place/Emirates+Bio+Farm+%D9%85%D8%B2%D8%B1%D8%B9%D8%A9+%D8%A7%D9%84%D8%A7%D9%85%D8%A7%D8%B1%D8%A7%D8%AA+%D8%A7%D9%84%D8%A8%D9%8A%D9%88%D9%84%D9%88%D8%AC%D9%8A%D8%A9%E2%80%AD/@24.6842221,55.7416149,15z/data=!4m5!3m4!1s0x0:0x27e86c61f8a1f2ff!8m2!3d24.6842221!4d55.7416149"
    },


]


export const SeventeenData = [

    {
        placeName:"CIRCUIT X",
        offset:[5,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.41796026033758, 54.33328519733207],
        link:"https://www.google.com/maps/place/Circuit+X/@24.4177307,54.3310965,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5e6f675b9224eb:0xd37fa4b7e691aed6!8m2!3d24.4177307!4d54.3332852"
    },

    {
        placeName:"SPACEWALK",
        offset:[5,-26],
        toolTipDirection: "top",
        locationPosition:[24.4424590119157, 54.38474259917746],
        link:"https://www.google.com/maps/place/SpaceWalk/@24.4422881,54.3824788,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5e688c99a9421b:0xe638ee1d9afc242b!8m2!3d24.4422596!4d54.3847153"
    },

    {
        placeName:"CLYMB",
        offset:[0,-24],
        toolTipDirection: "top",
        locationPosition:[24.48675242723798, 54.60652956932008],
        link:"https://www.google.com/maps/place/CLYMB+ABU+DHABI/@24.4860299,54.6066154,15z/data=!4m5!3m4!1s0x0:0x24e038c0d422d09a!8m2!3d24.4860299!4d54.6066154"
    }

]

export const EighteenData = [

    {
        placeName:"AL FORSAN SPORTS",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.419682338469578, 54.54915436082629],
        link:"https://www.google.com/maps/dir/24.4449844,+54.3576579/Al+Forsan+International+Sports+Resort,+Khalifa+City+,+Street+No.12,+Near+Abu+Dhabi+Golf+Club+-+Abu+Dhabi+-+United+Arab+Emirates/@24.4355165,54.383343,12z/data=!3m1!4b1!4m12!4m11!1m3!2m2!1d54.3576579!2d24.4449844!1m5!1m1!1s0x3e5e4693f9ef31fb:0xa6f7cd3284fa0d5!2m2!1d54.5483077!2d24.4088318!3e0"
    },


    {
        placeName:"YAS KARTZONE",
        offset:[5,-13],
        toolTipDirection: "bottom",
        locationPosition:[24.47654881226977, 54.60643518383822],
        link:"https://www.google.ae/maps/place/Yas+Kartzone/@24.4762803,54.6042143,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5e4580e6e3c18f:0x238544a728f283b4!8m2!3d24.4762855!4d54.6064484?hl=en-ae"
    },

    {
        placeName:"FERRARI WORLD 'KARTING ACADEMY' ",
        offset:[0,-23],
        toolTipDirection: "top",
        locationPosition:[24.496073950642117, 54.60591336938108],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/ferrari+world/@24.4951367,54.3422415,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e457e2d394a05:0x6076df4876c470a9!2m2!1d54.6070066!2d24.4837634"
    }
]

export const NineteenData = [

    {
        placeName:"LOUVRE MUSEUM",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.54060906932317, 54.39917588684854],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/louvre+museum/@24.5201511,54.307852,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e6724630067f1:0x42191ffb02753df9!2m2!1d54.3981368!2d24.5336954"
    },


    {
        placeName:"REEM ISLAND",
        offset:[5,-20],
        toolTipDirection: "top",
        locationPosition:[24.49622481587621, 54.40686130853023],
        link:"https://www.google.ae/maps/place/Al+Reem+Island/@24.492671,54.3909826,14z/data=!3m1!4b1!4m5!3m4!1s0x3e5e67becd78ccdd:0xad4befbe23b2d345!8m2!3d24.4942437!4d54.4068603?sa=X&hl=en-ae&ved=2ahUKEwiAqfvsn4XwAhXcQUEAHQZOB20Q8gEwG3oECDMQAQ&shorturl=1"
    },

    {
        placeName:"JUBAIL MANGROVE PARK",
        offset:[0,-23],
        toolTipDirection: "top",
        locationPosition:[24.551161569141875, 54.485770021599926],
        link:"https://www.google.ae/maps/dir/24.4449844,54.3576579/mangroves+walk/@24.4969687,54.3432911,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e5deaa4bcb5d5:0x56ecbf2da20ad44c!2m2!1d54.4854061!2d24.5451825?hl=en-ae"
    },

    {
        placeName:"MANGROVES HOTEL",
        offset:[0,-23],
        toolTipDirection: "top",
        locationPosition:[24.452041532936217, 54.43716851281138],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/Anantara+Eastern+Mangroves+Abu+Dhabi+Hotel,+Al+Salam+Street+-+Zone+1Eastern+Mangrove+-+Abu+Dhabi+-+United+Arab+Emirates/@24.4765727,54.3273052,12z/data=!3m1!4b1!4m10!4m9!1m1!4e1!1m5!1m1!1s0x3e5e42834cf5a8d9:0x402184736323bda0!2m2!1d54.4370134!2d24.4464355!3e0"
    },

    {
        placeName:"AL BAHIA SEA",
        offset:[0,-23],
        toolTipDirection: "top",
        locationPosition:[24.51, 54.56],
        link:"https://www.google.ae/maps/dir/24.4449844,54.3576579/al+bahia+sea/@24.4679658,54.3783884,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e507337afd29f:0x9d53efa925dff25c!2m2!1d54.6164811!2d24.5460439?hl=en-ae"
    }
]

export const TwentyData = [

    {
        placeName:"SHEIKH ZAYED GRAND MOSQUE",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.41359542513749, 54.47510414601987],
        link:"https://www.google.ae/maps/place/Sheikh+Zayed+Grand+Mosque/@24.4128334,54.4749754,15z/data=!4m5!3m4!1s0x0:0x248fbbcbefe2afc7!8m2!3d24.4128334!4d54.4749754?hl=en-ae"
    },


    {
        placeName:"SHEIKH KHALIFA MOSQUE",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.31459658911867, 55.749174772505626],
        link:"https://www.google.ae/maps/search/SHEIKH+KHALIFA+MOSQUE/@24.4662319,54.3352028,14z/data=!3m1!4b1?hl=en-ae"
    },


]


export const TwentyOneData = [

    {
        placeName:"JONES THE GROCER",
        offset:[-70,10],
        toolTipDirection: "down",
        locationPosition:[24.46965072542644, 54.34001583067992],
        link:"https://www.google.com/maps/place/Jones+the+Grocer/@24.4689281,54.33993,15z/data=!4m5!3m4!1s0x0:0x1e605df145f8f0ca!8m2!3d24.4689281!4d54.33993"
    },


    {
        placeName:"WILTON",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.49850674856773, 54.35764469282764],
        link:"https://www.google.ae/maps/dir/24.4156824,54.55468/wilton+world+trade+center/@24.4456977,54.316446,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e667ad9904e57:0xcf14572362258ed1!2m2!1d54.357791!2d24.4873195?hl=en-ae"
    },

    {
        placeName:"TEARTO",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.425434503003764, 54.47338196135983],
        link:"https://www.google.com/maps/place/Teatro+-+Fine+Dining+Restaurant/@24.4245944,54.4732103,15z/data=!4m5!3m4!1s0x0:0x3e884cd4b553247!8m2!3d24.4245944!4d54.4732103"
    },

    {
        placeName:"LANA'S PARTIPERFECT",
        offset:[-5,-10],
        toolTipDirection: "left",
        locationPosition:[24.3377274952433, 54.520500553980135],
        link:"https://www.google.com/maps/place/Lana's+Partiperfect/@24.3366913,54.5206293,15z/data=!4m5!3m4!1s0x0:0x1e5191a5c7b4d4b4!8m2!3d24.3366403!4d54.5206316"
    }


]


export const TwentyTwoData = [

    {
        placeName:"LOUVRE PARK",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.537188801432812, 54.39991886410983],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/louvre+park/@24.5192292,54.3089383,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e67a165fc1f1d:0xda888f2e31d004ae!2m2!1d54.4003094!2d24.5318516"
    },


    {
        placeName:"AL FAY PARK",
        offset:[5,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.492399256513362, 54.405109553980125],
        link:"https://www.google.ae/maps/place/Al+Fay+Park/@24.4915596,54.4052383,15z/data=!4m5!3m4!1s0x0:0x371ed63f7aa1254a!8m2!3d24.4915596!4d54.4052383?hl=en-aeS"
    },


    {
        placeName:"UMM AL EMARAT PARK",
        offset:[5,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.45219766489753, 54.38124465500261],
        link:"https://www.google.com/maps/dir//Umm+AlEmarat+Park+-+15th+Street,+Mushrif+Area+-+Abu+Dhabi+-+United+Arab+Emirates/@24.4519877,54.3790345,17z/data=!4m16!1m6!3m5!1s0x3e5e688571a9ea6f:0x9a408a4eb584a94c!2sUmm+AlEmarat+Park!8m2!3d24.4519877!4d54.3812232!4m8!1m0!1m5!1m1!1s0x3e5e688571a9ea6f:0x9a408a4eb584a94c!2m2!1d54.3812232!2d24.4519877!3e2?hl=en"
    },


    {
        placeName:"REEM CENTRAL PARK",
        offset:[5,-23],
        toolTipDirection: "top",
        locationPosition:[24.507686331298547, 54.40816185322136],
        link:"https://www.google.com/maps/dir//Umm+AlEmarat+Park+-+15th+Street,+Mushrif+Area+-+Abu+Dhabi+-+United+Arab+Emirates/@24.4519877,54.3790345,17z/data=!4m16!1m6!3m5!1s0x3e5e688571a9ea6f:0x9a408a4eb584a94c!2sUmm+AlEmarat+Park!8m2!3d24.4519877!4d54.3812232!4m8!1m0!1m5!1m1!1s0x3e5e688571a9ea6f:0x9a408a4eb584a94c!2m2!1d54.3812232!2d24.4519877!3e2?hl=en"
    },

    {
        placeName:"MANGROVE NATIONAL PARK",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.450342537850778, 54.41758602331304],
        link:"https://www.google.com/maps/place/Mangrove+National+Park/@24.456065,54.4178006,15z/data=!4m5!3m4!1s0x0:0x9c240c7576f324df!8m2!3d24.456065!4d54.4178006"
    }


]

export const TwentyThreeData = [

    {
        placeName:"MIRAJ MUSEUM",
        offset:[0,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.42164530070287, 54.46645538640174],
        link:"https://www.google.com/maps/place/Miraj+Museum/@24.4209224,54.4568172,15z/data=!4m5!3m4!1s0x0:0x9eb8f66915c52fea!8m2!3d24.4209224!4d54.4568172"
    },


    {
        placeName:"ABU DHABI NATIONAL HISTORY MUSEUM",
        offset:[-5,-35],
        toolTipDirection: "right",
        locationPosition:[24.428169105466935, 54.601661216358996],
        link:"https://www.google.com/maps/place/Abu+Dhabi+History+Museum+and+Aquarium/@24.4230547,54.4719905,15z/data=!4m5!3m4!1s0x0:0xce30d8bf3ea05273!8m2!3d24.4227798!4d54.4720814"
    },


    {
        placeName:"EMIRATES NATIONAL AUTO MUSEUM",
        offset:[5,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.11386594472269, 54.42013814602878],
        link:"https://www.google.com/maps/place/Emirates+National+Auto+Museum/@24.2782755,54.3439205,11z/data=!4m20!1m14!4m13!1m6!1m2!1s0x3e5e188976b4f463:0x48456e9d5ea557c0!2sEmirates+National+Auto+Museum+-+South+of+Abu+Dhabi,+Hamim+Road,+Off+E11,+Al+Dhafra+-+Abu+Dhabi!2m2!1d54.4201307!2d24.0988807!1m4!2m2!1d54.366009!2d24.449604!4e1!3e0!3m4!1s0x3e5e188976b4f463:0x48456e9d5ea557c0!8m2!3d24.0988807!4d54.4201307"
    },


    {
        placeName:"AL AIN PALACE MUSEUM",
        offset:[10,-20],
        toolTipDirection: "left",
        locationPosition:[24.21592991046638, 55.76086325398013],
        link:"https://www.google.com/maps/place/Al+Ain+Palace+Museum/@24.214971,55.760992,15z/data=!4m2!3m1!1s0x0:0x2169bb1bd3e87220?sa=X&ved=2ahUKEwjTnNOHmO3wAhXkgf0HHa_KA58Q_BIwJnoECFcQBQ"
    }


]


export const TwentyFourData = [

    {
        placeName:"DELMA ISLAND",
        offset:[0,-25],
        toolTipDirection: "top",
        locationPosition:[24.699485324881923, 52.27733982995082],
        link:"https://www.google.ae/maps/place/Dalma+Island/@24.2945931,51.1677208,7z/data=!4m5!3m4!1s0x3e43690f98796307:0x9098cc851121c9bb!8m2!3d24.5009972!4d52.3081346?hl=en-ae"
    },


    {
        placeName:"SHUWALHAT ISLAND",
        offset:[5,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.112850208817164, 52.43821071496522],
        link:"https://www.google.com/maps/place/Shuweihat+Island/@24.1113813,52.4307005,15z/data=!3m1!4b1!4m5!3m4!1s0x3e4320ceeeea487d:0xe8f63348cea04a48!8m2!3d24.1119844!4d52.4385546"
    },


    {
        placeName:"NURAL ISLAND",
        offset:[5,-25],
        toolTipDirection: "top",
        locationPosition:[24.615130161020925, 54.47719012192152],
        link:"https://www.google.ae/maps/place/Nurai+Island/@24.5855021,54.3901328,11z/data=!4m5!3m4!1s0x3e5e5fa9f8bd9ff5:0x9def453d67cd412d!8m2!3d24.6141924!4d54.4773144?hl=en-ae"
    }

]

export const TwentyFiveData = [

    {
        placeName:"WAREHOUSE 421",
        offset:[0,-25],
        toolTipDirection: "top",
        locationPosition:[24.518250421569103, 54.370747830679925],
        link:"https://www.google.com/maps/place/Warehouse421/@24.5172938,54.370662,15z/data=!4m5!3m4!1s0x0:0xa2d45a161b1007c!8m2!3d24.5172822!4d54.3706722?hl=en-us"
    },


    {
        placeName:"HOUSE OF ARTISANS",
        offset:[20,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.482503164750216, 54.3551795],
        link:"https://www.google.com/maps/place/House+Of+Artisans+-%D8%A8%D9%8A%D8%AA+%D8%A7%D9%84%D8%AD%D8%B1%D9%81%D9%8A%D9%8A%D9%86%E2%80%AD/@24.4814291,54.3551795,15z/data=!4m5!3m4!1s0x0:0xd98f899794c4e58a!8m2!3d24.4814291!4d54.3551795"
    },


    {
        placeName:"LOUVRE",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.54060906932317, 54.39917588684854],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/louvre+museum/@24.5201511,54.307852,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e6724630067f1:0x42191ffb02753df9!2m2!1d54.3981368!2d24.5336954"
    },

    { 
        placeName:"MANARAT AL SAADIYAT",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.50,54.44],
        link:"https://www.google.com/maps/dir/24.4449844,54.3576579/escape+rooms/@24.4557596,54.3416925,15z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e65e81d5f6021:0xaa1d41c9f5053406!2m2!1d54.3459311!2d24.4659489?hl=en-us"
    }

]


export const TwentySixData = [

    {
        placeName:"QASR AL WATAN",
        offset:[0,-25],
        toolTipDirection: "top",
        locationPosition:[24.462857002835623, 54.305711800000005],
        link:"https://www.google.ae/maps/place/Qasr+Al+Watan+%D9%82%D8%B5%D8%B1+%D8%A7%D9%84%D9%88%D8%B7%D9%86%E2%80%AD/@24.4627154,54.3057118,17z/data=!4m9!1m2!2m1!1sqasr+al+watan.!3m5!1s0x3e5e65976c721b57:0x75a1d867b5a5ed1a!8m2!3d24.4627154!4d54.3057118!15sCg5xYXNyIGFsIHdhdGFuLloeCg1xYXNyIGFsIHdhdGFuIg1xYXNyIGFsIHdhdGFukgESdG91cmlzdF9hdHRyYWN0aW9u?hl=en-ae"
    },


    {
        placeName:"EMIRATES PALACE",
        offset:[-5,-20],
        toolTipDirection: "right",
        locationPosition:[24.462600644900824, 54.3172833],
        link:"https://www.google.com/maps/place/Emirates+Palace+-+W+Corniche+Rd+-+Al+Ras+Al+Akhdar+-+Abu+Dhabi+-+United+Arab+Emirates/@24.4616436,54.3172833,17z/data=!4m2!3m1!1s0x3e5e6596f8aa2c21:0x634ef65bd7bd41c8"
    },


    {
        placeName:"QASR ALHOSN",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.486021017085463, 54.35450036583679],
        link:"https://www.google.ae/maps/place/Qasr+AlHosn/@24.4822419,54.3525343,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5e6671079e3853:0x6142fa1df9085a2f!8m2!3d24.482237!4d54.354723?hl=en-ae"
    },

    {
        placeName:"QASR ALMUWAIJI",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.40542178881909, 54.37691381349503],
        link:"https://www.google.ae/maps/place/Qasr+AlMuwaiji/@24.225231,55.726946,17z/data=!4m5!3m4!1s0x3e8ab16f77697abf:0xfafc043e46980e3d!8m2!3d24.225231!4d55.726946?hl=en-ae"
    }

]


export const TwentySevenData = [

    {
        placeName:"AL DHAFRA SALT LAKE",
        offset:[0,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.063128229522025, 53.27192754601987],
        link:"https://www.google.com/maps/place/HSN+LAKE/@24.0620898,53.2717988,15z/data=!4m5!3m4!1s0x0:0x8ad9e43c78c89bfb!8m2!3d24.0620898!4d53.2717988?hl=en"
    },


    {
        placeName:"ALWATHBA SALT LAKE",
        offset:[0,-20],
        toolTipDirection: "top",
        locationPosition:[24.25219924017405, 54.743740523300204],
        link:"https://www.google.com/maps/place/AlWathba+Salt+Lake+(Sabkha)/@24.2515145,54.7439551,15z/data=!4m5!3m4!1s0x0:0xd2cb8aa0a0556235!8m2!3d24.2515145!4d54.7439551"
    },


    {
        placeName:"AIN AL FAYDAH",
        offset:[5,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.08905166523309, 55.704535534331235],
        link:"https://www.google.com/maps/dir//Ain+Al+Faydah+-+Abu+Dhabi+-+United+Arab+Emirates/@24.0803147,55.6788722,14z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3e8abb6637cc036b:0x4b17f5adabe86d35!2m2!1d55.7045299!2d24.0875582"
    }
]

export const TwentyEightData = [

    {
        placeName:"CORNICHE",
        offset:[0,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.470120795657426, 54.335551212672954],
        link:"https://www.google.ae/maps/place/Corniche+Rd+-+Abu+Dhabi+-+United+Arab+Emirates/@24.4698718,54.3333196,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5e666247d85297:0x22faafaa0094a3dd!8m2!3d24.4698718!4d54.3355083?hl=en-ae"
    },

    {
        placeName:"MINA MARSA",
        offset:[5,-24],
        toolTipDirection: "left",
        locationPosition:[24.530146219968017, 54.379210630679914],
        link:"https://www.google.ae/maps/place/Marsa+Mina/@24.5293849,54.3791248,15z/data=!4m5!3m4!1s0x0:0x668f8f5c3ac3bd24!8m2!3d24.5293849!4d54.3791248?hl=en-ae"
    },

    {
        placeName:"MAMSHA AL SAADIYAT",
        offset:[5,-25],
        toolTipDirection: "top",
        locationPosition:[24.538724819591657, 54.4102581108293],
        link:"https://www.google.ae/maps/place/Mamsha+Al+Saadiyat/@24.5384662,54.4081016,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5e67d77059d465:0xf14feb90c98942eb!8m2!3d24.5384662!4d54.4102903?hl=en-ae"
    },

    {
        placeName:"MANGROVES WALK",
        offset:[10,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.522901707979546, 54.41253646267754],
        link:"https://www.google.ae/maps/place/Jubail+Mangrove+Park/@24.5264482,54.4422778,12z/data=!4m9!1m2!2m1!1smangrove+walk!3m5!1s0x3e5e5deaa4bcb5d5:0x56ecbf2da20ad44c!8m2!3d24.5451825!4d54.4854061!15sCg1tYW5ncm92ZSB3YWxrWh4KDW1hbmdyb3ZlIHdhbGsiDW1hbmdyb3ZlIHdhbGuSAQ9uYXR1cmVfcHJlc2VydmWaASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVTmphMlJMTUdKUkVBRQ?hl=en-ae"
    }

]


export const TwentyNineData = [

    {
        placeName:"ABU DHABI EQUESTRIAN CLUB",
        offset:[0,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.449039501134273, 54.376600686164615],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/Al+Mushrif+-+Abu+Dhabi/@24.4748212,54.2966065,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e688c3c99a193:0xf0e38619e5ca40f2!2m2!1d54.3757123!2d24.4429014"
    },

    {
        placeName:"SANAM STABLE",
        offset:[0,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.60859649680851, 54.692750915339964],
        link:"https://www.google.com/maps/place/Sanam+Stable/@24.6077186,54.692708,15z/data=!4m5!3m4!1s0x0:0x449ae72b3b764d96!8m2!3d24.6077189!4d54.6927081"
    },

    {
        placeName:"AL WATHBA STABLE",
        offset:[0,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.201552160325118, 54.7004944264734],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/al+wathba+stable/@24.3411394,54.3228394,10z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e2c32f4a9a415:0x184c335aa909a226!2m2!1d54.7028462!2d24.1786567"
    },

    {
        placeName:"AL MAGHAWEER STABLES",
        offset:[0,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.43180292782581, 54.80333885326012],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/Sweihan+Road+-+Al+Me'rad+-+Abu+Dhabi/@24.4574319,54.2979678,10z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5fb55860f279d7:0x65d8d008a748f7c9!2m2!1d54.7986717!2d24.4087409"
    }


]


export const ThirtyData = [

    {
        placeName:"JET SKIING",
        offset:[0,-25],
        toolTipDirection: "top",
        locationPosition:[24.513496373560077, 54.37436483067992],
        link:"https://www.google.com/maps/place/Blue+Whale+Jet+Ski+Abu+Dhabi+Rental/@24.512774,54.374279,15z/data=!4m5!3m4!1s0x0:0x8c7973a149291bfb!8m2!3d24.512774!4d54.374279"
    },

    {
        placeName:"ABU DHABI PARASAIL",
        offset:[-10,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.48220928162071, 54.34603330000001],
        link:"https://www.google.com/maps/place/Abu+Dhabi+Parasail/@24.4813305,54.3460333,15z/data=!4m5!3m4!1s0x0:0x9a91bee3d4b7fe29!8m2!3d24.4813305!4d54.3460333"
    },

    {
        placeName:"REEM CENTRAL PARK",
        offset:[0,-15],
        toolTipDirection: "right",
        locationPosition:[24.505265520527242, 54.407839607960256],
        link:"https://www.google.ae/maps/place/Reem+Central+Park/@24.5043869,54.4080971,15z/data=!4m5!3m4!1s0x0:0xcc58afc6cdb22144!8m2!3d24.5043869!4d54.4080971?hl=en-ae"
    },

    {
        placeName:"MANGROVES HOTEL",
        offset:[0,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.45493781580743, 54.43674278462022],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/Anantara+Eastern+Mangroves+Abu+Dhabi+Hotel,+Al+Salam+Street+-+Zone+1Eastern+Mangrove+-+Abu+Dhabi+-+United+Arab+Emirates/@24.4765727,54.3273052,12z/data=!3m1!4b1!4m10!4m9!1m1!4e1!1m5!1m1!1s0x3e5e42834cf5a8d9:0x402184736323bda0!2m2!1d54.4370134!2d24.4464355!3e0"
    },


    {
        placeName:"AL FORSAN INTERNATIONAL SPORTS RESORT",
        offset:[5,-10],
        toolTipDirection: "bottom",
        locationPosition:[24.419682338469578, 54.54915436082629],
        link:"https://www.google.com/maps/dir/24.4449844,+54.3576579/Al+Forsan+International+Sports+Resort,+Khalifa+City+,+Street+No.12,+Near+Abu+Dhabi+Golf+Club+-+Abu+Dhabi+-+United+Arab+Emirates/@24.4355165,54.383343,12z/data=!3m1!4b1!4m12!4m11!1m3!2m2!1d54.3576579!2d24.4449844!1m5!1m1!1s0x3e5e4693f9ef31fb:0xa6f7cd3284fa0d5!2m2!1d54.5483077!2d24.4088318!3e0"
    },


    {
        placeName:"YAS WATER WORLD",
        offset:[5,-24],
        toolTipDirection: "top",
        locationPosition:[24.5011248473992, 54.62],
        link:"https://www.google.com/maps/dir/24.5066392,54.357648/yas+waterworld/@24.4970635,54.3385617,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e457acea5a845:0x12d3db84713d00d9!2m2!1d54.599647!2d24.487617"
    }


]